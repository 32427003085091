/**
 * Use invariant() to assert state which your program assumes to be true.
 *
 * In production, an {@link invariant.Violation} will be thrown without the error message.
 *
 * @throws {invariant.Violation}
 */
export function invariant(condition: unknown, message: string): asserts condition {
  if (condition) {
    return;
  }

  if (process.env.NODE_ENV === 'production') {
    throw new invariant.Violation();
  }

  throw new invariant.Violation(message);
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace invariant {
  /**
   * Represents an error that occurs when **the application reaches an invalid state** due to a
   * breach of assumptions or constraints that were expected to hold true.
   *
   * ⚠️ This error should never be thrown manually. It should only be thrown by {@link invariant}.
   */
  export class Violation extends Error {
    constructor(message?: string) {
      super(message);
      this.name = 'InvariantViolation';
      // the invariant function should be the start of the error stack, not the line within the
      //  function where the error is thrown: https://v8.dev/docs/stack-trace-api#customizing-stack-traces
      Error.captureStackTrace(this, invariant);
    }
  }
}
