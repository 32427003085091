import { invariant } from '~/utils/invariant';
/**
 * Represents the Mellon environment in which the application is running. This can either be `dev`,
 * `test`, or `prod`. Within these, the `nodeEnv` can be `development`, `preview`, or `production`.
 */ export const environment = process.env.ENVIRONMENT || process.env.NEXT_PUBLIC_ENVIRONMENT;
invariant([
    'dev',
    'test',
    'prod'
].includes(environment), "Invalid environment: ".concat(environment));
/**
 * Represents the _Netlify_ environment in which the application is running.
 *
 * - `development`: The application is running locally.
 * - `preview`: The application is running on builder or a branch deploy.
 * - `production`: The application is running on the main site (dev.mellon.org, test.mellon.org, mellon.org).
 */ export let context;
if (process.env.CONTEXT === 'production') {
    context = 'production';
} else if ("production" === 'development' || !process.env.NETLIFY && !process.env.NETLIFY_LOCAL) {
    context = 'development';
} else {
    context = 'preview';
}
