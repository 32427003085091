// Try to keep cookie names short (preferably 4 characters or less) and prefix
//   them with `mf_` to avoid collisions.
export enum StorageKey {
  INTRO_ANIMATION = 'mf_ia',
  USER = 'mf_u',

  // Breadcrumbs
  BREADCRUMB_ENTRIES = 'mf_bce',
  BREADCRUMB_CURRENT = 'mf_bcc',
  BREADCRUMB_LABELS = 'mf_bcl',

  // Query parameters
  NEXT = 'next',
  PATH = 'path',
}
