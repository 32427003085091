import dynamic from 'next/dynamic';
import { usePathname } from 'next/navigation';
import { Suspense, createContext, useMemo } from 'react';

import { useBreadcrumbStack } from './useBreadcrumbStack';

const Stack = dynamic(() => import('./stack').then(m => m.Stack));

export interface BreadcrumbContext {
  previousPage: { path: string; label: string } | undefined;
}

export const BreadcrumbContext = createContext<BreadcrumbContext>({
  previousPage: undefined,
});

export function BreadcrumbProvider({ children }: { children: React.ReactNode }) {
  const { current, labels } = useBreadcrumbStack();
  const pathname = usePathname();

  const previousPageBreadcrumb = useMemo(() => {
    // return if there is no previous page, the user navigated to the current page via the navigation, or the previous page is the homepage
    if (current.length <= 1) {
      return;
    }
    let previousPageIndex = current.length - 1;
    while (previousPageIndex > -1) {
      const previousPage = labels[current[previousPageIndex]];
      if (previousPage?.path !== pathname) {
        break;
      }
      previousPageIndex--;
    }
    const previousPage = labels[current[previousPageIndex]];
    if (previousPage?.path === '/') {
      return;
    }
    return previousPage;
  }, [current, labels, pathname]);

  return (
    <BreadcrumbContext.Provider value={{ previousPage: previousPageBreadcrumb }}>
      <Suspense>
        <Stack />
      </Suspense>
      {children}
    </BreadcrumbContext.Provider>
  );
}
